export const validateLogin = (login) => /^[\w\@\.\-]{3,100}$/.test(login);

export const validatePassword = (password) =>
  /^[\w\!\"\#\$\%\&\'\(\)\*\+\,\-\.\/\:\;\<\=\>\?\@\[\\\]\^\`\{\|\}\~]{6,32}/.test(
    password
  );

export const validateEmail = (t) =>
  /^[\d\w\+\-\.]{2,99}\@[\w\-]{1,99}\.\w{1,8}$/.test(t);
export const validateCharactersNum = (t) => t.length >= 4;
