import { CustomCommand } from "reactotron-core-client";
import { AdminActions } from "./../logic/admin/AdminRedux";
import { store } from "./../store/store";

const SearchUsers: CustomCommand = {
  command: "Search Users",
  description: "Search Users",
  handler: () => {
    store.dispatch(AdminActions.searchUsers.request({}));
  },
};

const commands = [SearchUsers];

export { commands };
