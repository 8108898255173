import { useSelector, useDispatch } from "react-redux";
import { AdminActions } from "../logic/admin/AdminRedux";
import { useState, useEffect } from "react";
import { adminSelector } from "../logic/admin/AdminSelectors";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableCell,
  Container,
  Typography,
  TextField,
  Modal,
} from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { UserListHead, UserMoreMenu } from "../components/_dashboard/user";
import Page from "../components/Page";
import FormControl from "@material-ui/core/FormControl";
import NativeSelect from "@material-ui/core/NativeSelect";
import { makeStyles } from "@material-ui/styles";
import { useSearchValue } from "src/helpers/customHooks";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { divStyle, divStyle2, inputStyle3, inputStyle6 } from "./styles/styles";
import {
  validateCharactersNum,
  validateEmail,
} from "src/helpers/ValidationHelper";

// Global constants
const TABLE_HEAD = [
  { id: "Name", label: "Name", alignRight: false },
  { id: "Email", label: "Email", alignRight: false },
  { id: "Role", label: "Role", alignRight: false },
  { id: "Store", label: "Store", alignRight: false },
];

const ROLES_DATA = ["Any role", "new_hire", "admin", "super_user", "basic"];
const STORES_DATA = [
  {
    store_id: 1000000000000000,
    store_name: "Any store",
  },
];
const ROLE_DEFAULT = ROLES_DATA[0];
const STORE_DEFAULT = STORES_DATA[0].store_id;
const SERVICE_DEFAULT = "central";

export default function Users() {
  const useStyles = makeStyles((theme: any) => ({
    margin: {
      margin: theme.spacing(1),
    },
    row: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  const deleteEventHandler = (userId: string, serviceId: string) => {
    dispatch(
      AdminActions.deleteUser.request({
        userId: userId,
        serviceId: serviceId,
      })
    );
  };

  const dispatch = useDispatch();

  const {
    searchUsers: { data: searchUsersData },
    searchUsersByStore: { data: searchUsersByStoreData },
    listAllStores: { data: listAllStoresData },
    listAllStoresModal: { data: listAllStoresModalData },
    listServices: { data: listServicesData },
    addUser: { data: addUsersData },
    deleteUser: { data: deleteUsersData },
  } = useSelector(adminSelector);

  const [role, setRole] = useState(ROLE_DEFAULT);
  const [store, setStore] = useState(STORE_DEFAULT);
  const [services, setService] = useState(SERVICE_DEFAULT);
  const [servicesForm, setServiceForm] = useState(SERVICE_DEFAULT);
  const [servicesList, setServiceList] = useState();
  const [storeModal, setStoreModal] = useState(STORE_DEFAULT);
  const [storeList, setStoreList] = useState(STORES_DATA);
  const [storeList2, setStoreList2] = useState(STORES_DATA);
  const [storeList2Modal, setStoreList2Modal] = useState(STORES_DATA);
  const [email, emailInner, setEmailInner] = useSearchValue("");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const addUser = (email: string, password: string) => {
    setEmailError(false);
    setPasswordError(false);
    if (validateEmail(adminEmail) && validateCharactersNum(password)) {
      dispatch(
        AdminActions.addUser.request({
          email: email ? email : undefined,
          password: password ? password : undefined,
          role: "admin",
          store_id: storeModal,
          serviceId: servicesForm,
        })
      );
      setAdminEmail("");
      setPassword("");
      setIsOpenModal(false);
      initialRequest();
    } else {
      if (!validateEmail(adminEmail)) {
        setEmailError(true);
      }
      if (!validateCharactersNum(password)) {
        setPasswordError(true);
      }
    }
  };

  const initialRequest = () => {
    if (store !== 1000000000000000) {
      console.log(store);
      const data = {
        role: role !== ROLE_DEFAULT ? role.toLowerCase() : undefined,
        email: email ? email : undefined,
        storeId: store,
        serviceId: services,
      };
      for (const key of Object.keys(data)) {
        if (data[key] === undefined) delete data[key];
      }
      dispatch(AdminActions.searchUsersByStore.request(data));
      dispatch(AdminActions.searchUsers.success(undefined as any));
    } else {
      const data = {
        role: role !== ROLE_DEFAULT ? role.toLowerCase() : undefined,
        email: email ? email : undefined,
        serviceId: services,
      };
      for (const key of Object.keys(data)) {
        if (data[key] === undefined) delete data[key];
      }
      dispatch(AdminActions.searchUsers.request(data));
      dispatch(AdminActions.searchUsersByStore.success(undefined as any));
    }
  };

  useEffect(() => {
    dispatch(AdminActions.listAllStores.success(undefined as any));
    dispatch(
      AdminActions.listAllStores.request({
        serviceId: services,
      })
    );
  }, [services]);

  useEffect(() => {
    dispatch(AdminActions.listAllStoresModal.success(undefined as any));
    dispatch(
      AdminActions.listAllStoresModal.request({
        serviceId: servicesForm,
      })
    );
  }, [servicesForm]);

  useEffect(() => {
    dispatch(AdminActions.listServices.request({}));
  }, []);

  useEffect(() => {
    initialRequest();
  }, [
    store,
    role,
    email,
    addUsersData,
    deleteUsersData,
    services,
    servicesForm,
  ]);

  const storeChoose = () => {
    if (store !== STORES_DATA[0].store_id) {
      return searchUsersByStoreData;
    } else {
      return searchUsersData;
    }
  };

  useEffect(() => {
    if (listAllStoresData) {
      setStoreList(listAllStoresData.data.allStoresWithDefault);
      setStoreList2(listAllStoresData.data.allStores);
      setStoreModal(listAllStoresData.data.allStores[0].store_id);
    }
  }, [listAllStoresData, services]);

  useEffect(() => {
    if (listAllStoresModalData) {
      setStoreList2Modal(listAllStoresModalData.data.allStores);
      setStoreModal(listAllStoresModalData.data.allStores[0].store_id);
    }
  }, [listAllStoresModalData, servicesForm]);

  useEffect(() => {
    if (listServicesData) {
      setService(listServicesData.services[0]._id);
      setServiceList(listServicesData);
    }
  }, [listServicesData]);

  return (
    <>
      {/* @ts-ignore */}
      <Page title="Advin | Users">
        <Container>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={5}
          >
            <Typography variant="h4" gutterBottom>
              Users
            </Typography>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <div className={classes.row}>
                <TextField
                  size={"small"}
                  value={emailInner}
                  onChange={(event) => setEmailInner(event.target.value)}
                  style={inputStyle3}
                  id="standard-basic"
                  label="Email"
                />
                {listServicesData && (
                  <FormControl className={classes.margin}>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={services}
                      onChange={(event) => {
                        setService(event.target.value);
                      }}
                    >
                      {listServicesData.services.map((item) => {
                        return <option value={item._id}>{item.name}</option>;
                      })}
                    </NativeSelect>
                  </FormControl>
                )}
                {listAllStoresData && (
                  <FormControl className={classes.margin}>
                    <NativeSelect
                      id="demo-customized-select-native"
                      value={store}
                      onChange={(event) => {
                        setStore(parseInt(event.target.value));
                      }}
                    >
                      {storeList.map((item) => {
                        return (
                          <option value={item.store_id}>
                            {item.store_name}
                          </option>
                        );
                      })}
                    </NativeSelect>
                  </FormControl>
                )}

                <FormControl className={classes.margin}>
                  <NativeSelect
                    id="demo-customized-select-native"
                    value={role}
                    onChange={(event) => {
                      setRole(event.target.value);
                    }}
                  >
                    {ROLES_DATA.map((item) => {
                      return <option value={item}>{item}</option>;
                    })}
                  </NativeSelect>
                </FormControl>
              </div>

              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button
                  onClick={() => {
                    setIsOpenModal(true);
                    setAdminEmail("");
                    setPassword("");
                    setPasswordError(false);
                    setEmailError(false);
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              </Stack>
            </Stack>
          </Stack>

          <Card>
            <DragDropContext onDragEnd={() => {}}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <>
                    {storeChoose()?.results[0]?.users ? (
                      <Table
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        <UserListHead headLabel={TABLE_HEAD} />

                        {storeChoose().results[0]?.users.map((row, index) => {
                          const {
                            emp_id,
                            _id,
                            role,
                            smallPhoto,
                            name,
                            email,
                            store_name,
                            serviceId,
                          } = row;

                          return (
                            <Draggable
                              isDragDisabled={true}
                              key={emp_id}
                              draggableId={emp_id}
                              index={index}
                            >
                              {(provided) => (
                                <TableRow
                                  hover
                                  key={index}
                                  tabIndex={-1}
                                  role="checkbox"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <TableCell align="left">
                                    {index + 1}
                                  </TableCell>

                                  <TableCell
                                    component="th"
                                    scope="row"
                                    padding="none"
                                  >
                                    <Stack
                                      direction="row"
                                      alignItems="center"
                                      spacing={2}
                                    >
                                      <Avatar alt={name} src={smallPhoto} />
                                      <Typography variant="subtitle2" noWrap>
                                        {name ? name : "--"}
                                      </Typography>
                                    </Stack>
                                  </TableCell>
                                  <TableCell align="left">{email}</TableCell>
                                  <TableCell align="left">{role}</TableCell>
                                  <TableCell align="left">
                                    {store_name}
                                  </TableCell>
                                  <TableCell align="right">
                                    <UserMoreMenu
                                      onDelete={() => {
                                        deleteEventHandler(emp_id, serviceId);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              )}
                            </Draggable>
                          );
                        })}
                      </Table>
                    ) : null}
                  </>
                )}
              </Droppable>
            </DragDropContext>
          </Card>
          {/* {searchUsersData?.results[0]?.users?.length %
            PAGINATION_CONFIG.limit ===
            0 && (
            <LoadingMore
              onPress={() => {
                handleSearchUsers(searchUsersData.users.length);
              }}
              loading={searchUsersFetching}
            />
          )} */}
        </Container>
        <ToastContainer />
      </Page>
      <Modal
        style={divStyle2}
        open={isOpenModal}
        onClose={() => {
          setIsOpenModal(false);
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {/* @ts-ignore */}
        <Card style={divStyle} elevation={3}>
          {listServicesData && (
            <FormControl className={classes.margin}>
              <NativeSelect
                id="demo-customized-select-native"
                value={servicesForm}
                onChange={(event) => {
                  setServiceForm(event.target.value);
                }}
              >
                {listServicesData.services.map((item) => {
                  return <option value={item._id}>{item.name}</option>;
                })}
              </NativeSelect>
            </FormControl>
          )}
          {servicesForm !== "central" && (
            <FormControl className={classes.margin}>
              <NativeSelect
                id="demo-customized-select-native"
                value={storeModal}
                onChange={(event) => {
                  setStoreModal(parseInt(event.target.value));
                }}
              >
                {storeList2Modal.map((item) => {
                  return (
                    <option value={item.store_id}>{item.store_name}</option>
                  );
                })}
              </NativeSelect>
            </FormControl>
          )}
          <TextField
            value={adminEmail}
            type={"email"}
            error={emailError}
            inputProps={{ inputMode: "email" }}
            onChange={(event) => setAdminEmail(event.target.value)}
            style={inputStyle6}
            id="standard-basic"
            label="Email"
            helperText={emailError && "Incorrect email"}
          />
          <TextField
            error={passwordError}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            style={inputStyle6}
            id="standard-basic"
            label="Password"
            helperText={passwordError && "Incorrect password"}
          />
          <>
            <Button
              disabled={servicesForm === "central"}
              onClick={() => {
                addUser(adminEmail, password);
              }}
              variant="contained"
            >
              {"Add Admin"}
            </Button>
          </>
        </Card>
      </Modal>
    </>
  );
}
