import { Icon } from "@iconify/react";
import peopleFill from "@iconify/icons-eva/people-fill";
import calendar3Event from "@iconify/icons-bi/calendar3-event";
import footballIcon from "@iconify/icons-icon-park-outline/football";
import gridOutline from "@iconify/icons-eva/grid-outline";
import clockOutline from "@iconify/icons-eva/clock-outline";
import compassOutline from "@iconify/icons-eva/compass-outline";
import copyFill from "@iconify/icons-eva/copy-fill";
import globe2Fill from "@iconify/icons-eva/globe-2-fill";
import arrowCircleUpOutline from "@iconify/icons-eva/arrow-circle-up-outline";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import tag from "@iconify/icons-eva/bookmark-fill";
import globe from "@iconify/icons-eva/globe-2-fill";
import category from "@iconify/icons-eva/pricetags-fill";
import products from "@iconify/icons-eva/shopping-bag-fill";
import services from "@iconify/icons-eva/monitor-fill";
import surveys from "@iconify/icons-eva/list-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "Users",
    path: "/home/users",
    icon: getIcon(peopleFill),
  },
];

export default sidebarConfig;
