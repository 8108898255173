import { combineReducers } from 'redux';
import { userReducer } from '../logic/user/UserRedux';
import { adminReducer } from '../logic/admin/AdminRedux';

export const rootReducer = combineReducers({
  user: userReducer,
  admin: adminReducer
});

export type RootState = ReturnType<typeof rootReducer>;
