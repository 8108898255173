import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import editFill from "@iconify/icons-eva/edit-fill";
import imageFill from "@iconify/icons-eva/image-fill";
import { Link as RouterLink } from "react-router-dom";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
import moreVerticalFill from "@iconify/icons-eva/more-vertical-fill";
import approve from "@iconify/icons-eva/checkmark-fill";
import reject from "@iconify/icons-eva/close-fill";
// material
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";

// ----------------------------------------------------------------------
type UserMoreMenuProps = {
  onEdit?: () => void;
  onDelete?: () => void;
  onSetPhoto?: () => void;
  onApprove?: () => void;
  onReject?: () => void;
};

export default function UserMoreMenu({
  onEdit,
  onDelete,
  onSetPhoto,
  onApprove,
  onReject
}: UserMoreMenuProps) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        {onDelete &&
          <MenuItem onClick={onDelete} sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={trash2Outline} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        }

        {onEdit &&
          <MenuItem
            onClick={onEdit}
            component={RouterLink}
            to="#"
            sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={editFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Edit"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        }

        {onSetPhoto &&
          <MenuItem
            onClick={onSetPhoto}
            component={RouterLink}
            to="#"
            sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={imageFill} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Set photo"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        }

        {onApprove &&
          <MenuItem
            onClick={onApprove}
            component={RouterLink}
            to="#"
            sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={approve} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Approve"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        }

        {onReject &&
          <MenuItem
            onClick={onReject}
            component={RouterLink}
            to="#"
            sx={{ color: "text.secondary" }}>
            <ListItemIcon>
              <Icon icon={reject} width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Reject"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        }
      </Menu>
    </>
  );
}
