import React from 'react';
import clsx from 'clsx';
import { createStyles, Theme, } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import palette from '../../theme/palette';
import { useMediaQuery } from './hooks';

type MeterialInputType = {
  password?: boolean;
  validation?: boolean;
  borderRadius?: boolean;
  label: string;
  errorText?: string;
  value: string;
  handleChange(event: React.ChangeEvent<HTMLInputElement>): void;
}

const MaterialInput: React.FC<MeterialInputType> = React.memo(({
  password,
  validation,
  borderRadius,
  label,
  errorText,
  value,
  handleChange,
}) => {

  const black = palette.common.black;

  const validationColor = validation ? black : palette.error.main;
  const isRowBased = useMediaQuery('(max-device-width: 575px)');

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        // display: 'flex',
        // flexWrap: 'wrap',
        // marginTop: 28,
        width: '100%',
        // marginBottom: 0,

        '& label.Mui-focused': {
          color: black,
        },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
          transform: 'translate(16px, -24px) scale(1.1)',
          color: validationColor
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: black,
        },
        '& .PrivateNotchedOutline-legendNotched': {
          width: 0
        },
        // '& .MuiOutlinedInput-root': {
        //   width: isRowBased ? 256 : 416,
        //   height: isRowBased ? 42 : 58,
        //   /* borderRadius: 4, */
        //   /* margin: '24 0 24 0', */

        //   '& fieldset': {
        //     borderColor: black,
        //     '& legend': {
        //       maxWidth: 0
        //     },
        //   },
        //   '&:hover fieldset': {
        //     borderColor: validationColor
        //   },

        //   '&.Mui-focused fieldset': {
        //     borderColor: validationColor
        //   },

        // },
      },
      margin: {
        margin: theme.spacing(1),
      },
      withoutLabel: {
        marginTop: theme.spacing(3),
      },
      textField: {
        width: '25ch',
      },
    }),
  );
  // @ts-ignore
  const classes = useStyles();

  const [showPassword, setShowPassword] = React.useState(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    // @ts-ignore
    <div style={{width: '100%'}} className={classes.root}>
      <FormControl
        variant="outlined"
        style={{ borderRadius: borderRadius ? 4 : 0, width: '100%' }}
      >
        <InputLabel htmlFor="outlined-adornment-password">
          {label}
        </InputLabel>
        <OutlinedInput
          error={!validation}
          id="outlined-adornment-password"
          type={password ? (showPassword ? 'text' : 'password') : 'text'}
          value={value}
          onChange={handleChange}
          endAdornment={
            password
              ?
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <Icon icon={showPassword? eyeFill: eyeOffFill} />
                </IconButton>
              </InputAdornment>
              :
              null
          }
        />
        {(!validation && errorText) &&
          <FormHelperText error={!validation} style={{ width: 426 }}>
            {errorText}
          </FormHelperText>
        }


      </FormControl>
    </div>
  );

})

export { MaterialInput }