import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import {
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "./../../store/restHelper";
import { BaseErrorType, NodeRestStateType } from "./../../store/restHelper.d";
import {
  UserLoginRestActions,
  UserLogoutRestActions,
  UserTokenRelevanceCheckRestActions,
  GetSelfRestActions,
} from "./UserActions";
import fp from "lodash/fp";

type TokenRelevanceCheckResponse = {
  tokenExpiried: boolean;
};

export type TokenRelevanceCheckPayload = { error: BaseErrorType };
export type LoginPayload = {
  email: string;
  password: string;
};
export type CurrentLangT = `en` | `ru` | `es`;

export type UserRole =
  | undefined
  | "empty"
  | "basic"
  | "subscriber"
  | "moderator"
  | "admin";

export type SpecialistInfo = {};

export type SubscriptionInfo = {
  type: "apple" | "google";
  purchaseDate: number;
  expirationDate: number;
};

export type LocalesString = {
  en: string;
  es?: string;
  ru?: string;
};

export type LocalesStringStrict = {
  en: string;
  es: string;
  ru: string;
};

type UserLoginInfo = {
  userId?: string;
  userRole?: string;
  access_token?: string;
  token_type?: string;
  message?: string;
};

const userTokenRelevanceCheckRestActions = createRestActions<
  typeof UserTokenRelevanceCheckRestActions,
  TokenRelevanceCheckResponse,
  TokenRelevanceCheckPayload
>(UserTokenRelevanceCheckRestActions);

const userLoginRestActions = createRestActions<
  typeof UserLoginRestActions,
  UserLoginInfo,
  LoginPayload
>(UserLoginRestActions);

const userLogoutRestActions = createRestActions<
  typeof UserLogoutRestActions,
  void
>(UserLogoutRestActions);

export type GetSelfResponse = {
  role: UserRole;
  specialist: boolean;
  specialistInfo: null | SpecialistInfo;
  origPhoto: string;
  smallPhoto: string;
  _id: string;
  name: string;
  email: string;
  message?: string;
  dailyNotifications: boolean;
  currentSubscription?: SubscriptionInfo;
};

const getSelfRestActions = createRestActions<
  typeof GetSelfRestActions,
  GetSelfResponse
>(GetSelfRestActions);

const UserActions = {
  changeStep: createAction<UserStep>("user/changeStep"),
  login: userLoginRestActions,
  tokenRelevanceCheck: userTokenRelevanceCheckRestActions,
  logout: userLogoutRestActions,
  currentLang: createAction<CurrentLangT>(`user/currentLang`),
  storeTokenToRedux: createAction<string>("user/storeTokenToRedux"),
  storeUserIdToRedux: createAction<string | undefined>("user/storeUserIdRedux"),
  storeUserRoleToRedux: createAction<string | undefined>(
    "user/storeUserRoleRedux"
  ),
  getSelf: getSelfRestActions,
  setMyProfile: createAction<GetSelfResponse | undefined>("user/setMyProfile"),
};

type UserRestNodes = "login" | "logout" | "tokenRelevanceCheck" | "getSelf";

type UserStep = "login" | "uploadExcel" | "forgotPassword" | "homeView";

type UserStore = {
  step: UserStep;
  userToken: string;
  userId: string | undefined;
  userRole: string | undefined;
  currentLang: CurrentLangT;
  profile: GetSelfResponse | undefined;
};

const initUserStore: UserStore = {
  step: "login",
  userToken: ``,
  userId: undefined,
  userRole: undefined,
  currentLang: `en`,
  profile: undefined,
};

const initialRestState = {
  login: getDefaultRestState<UserLoginInfo>(),
  tokenRelevanceCheck: getDefaultRestState(),
  logout: getDefaultRestState(),
  getSelf: getDefaultRestState(),
};

type UserState = NodeRestStateType<
  UserRestNodes,
  UserStore & typeof initialRestState
>;

type Builder = ActionReducerMapBuilder<UserState>;

const userReducer = createReducer(
  { ...initUserStore, ...initialRestState },
  (builder) =>
    (
      fp.flow([
        addRestReducers(userLoginRestActions, "login"),
        addRestReducers(userLogoutRestActions, "logout"),

        addRestReducers(
          userTokenRelevanceCheckRestActions,
          "tokenRelevanceCheck"
        ),
        addRestReducers(getSelfRestActions, "getSelf"),
      ])(builder) as Builder
    )
      .addCase(UserActions.storeTokenToRedux, (state, action) => {
        state.userToken = action.payload;
      })
      .addCase(UserActions.storeUserIdToRedux, (state, action) => {
        state.userId = action.payload;
      })
      .addCase(UserActions.storeUserRoleToRedux, (state, action) => {
        state.userRole = action.payload;
      })
      .addCase(UserActions.changeStep, (state, action) => {
        state.step = action.payload;
      })
      .addCase(UserActions.currentLang, (state, action) => {
        state.currentLang = action.payload;
      })
      .addCase(UserActions.setMyProfile, (state, action) => {
        state.profile = action.payload;
      })
);

export { userReducer, UserActions };
