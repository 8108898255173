import { restActionCreatorHelper } from 'src/helpers/restActionCreatorHelper';

const userRestAction = restActionCreatorHelper(`user`);

export const UserLoginRestActions = userRestAction('login');

export const UserLogoutRestActions = userRestAction('logout');

export const GetSelfRestActions = userRestAction('get_self');

export const UserTokenRelevanceCheckRestActions = userRestAction("tokenRelevanceCheck");