import { Icon } from "@iconify/react";
import { useRef, useState } from "react";
import homeFill from "@iconify/icons-eva/home-fill";
import { useDispatch, useSelector } from "react-redux";
import { UserActions } from "../../logic/user/UserRedux";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import personFill from "@iconify/icons-eva/person-fill";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import gridOutline from "@iconify/icons-eva/grid-outline";
import globe2Fill from "@iconify/icons-eva/globe-2-fill";
import copyFill from "@iconify/icons-eva/copy-fill";
import arrowCircleUpOutline from "@iconify/icons-eva/arrow-circle-up-outline";
import logo from "./assets/logo.png";

import { Link as RouterLink } from "react-router-dom";
// material
import { alpha } from "@material-ui/core/styles";

import {
  Button,
  Box,
  Divider,
  MenuItem,
  Typography,
  Avatar,
  IconButton,
} from "@material-ui/core";
// components
import MenuPopover from "../../components/MenuPopover";
//
import account from "../../_mocks_/account";
import { userSelector } from "src/logic/user/UserSelectors";

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: "Users",
    icon: peopleFill,
    linkTo: "/home/users",
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const dispatch = useDispatch();

  const { profile } = useSelector(userSelector);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const onLogout = () => {
    dispatch(UserActions.logout.request());
  };

  const style = {
    width: 50,
    height: 50,
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 50,
          height: 50,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              position: "absolute",
            },
          }),
        }}
      >
        <img style={style} src={logo} alt="Logo" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {profile?.name ?? "Advin"}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {profile?.email ?? "advin@gmail.com"}
          </Typography>
        </Box>
        <Divider sx={{ my: 1 }} />
        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            onClick={() => {
              onLogout();
            }}
            fullWidth
            color="inherit"
            variant="outlined"
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
