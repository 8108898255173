import React, { useEffect } from 'react';
import './styles/auth.css';
import { userSelector } from '../logic/user/UserSelectors';
import { useSelector, useDispatch } from 'react-redux';
import Login from 'src/pages/Login';
import Router from 'src/routes';
import { UserActions } from 'src/logic/user/UserRedux';

const Auth: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const { userToken } = useSelector(userSelector);

    // ComponentDidMount
    useEffect(() => {
        dispatch(UserActions.getSelf.request());
    }, []);

    return (
        <>
            {userToken ?
                <Router />
                :
                <Login />
            }
        </>
    )
})

export { Auth }