import { AppConfig } from "AppConfig";

const config: AppConfig = {
  useReactotron: true,
  systemId: "advin",
  authURL: "http://46.101.92.209:81/api/auth/login",
  baseURL: "http://46.101.92.209:81/api",
};

export { config };
