import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import _ from "lodash";
import { put, call, select } from "redux-saga/effects";
import { api, extractError, RequestError } from "../../api";
import { GetSelfResponse, UserActions } from "./UserRedux";
import { takeLeading } from "./../../store/sagaHelper";
import { store } from "./../../store/store";
import { BaseErrorType } from "./../../store/restHelper.d";
import { userSelector } from "./UserSelectors";
import { toast } from "react-toastify";
import { LoginResponse, LogoutResponse } from "./../../types/api";

function* loginRequest(action: Action) {
  if (UserActions.login.request.match(action)) {
    try {
      const error = {};
      const loginResponse: ApiResponse<LoginResponse> = yield call(
        api.loginPost,
        action.payload
      );
      if (loginResponse.ok && loginResponse.data) {
        const responseData = loginResponse.data;
        yield put(
          UserActions.login.success({
            userId: responseData?.userId,
            userRole: responseData?.userRole,
            access_token: responseData?.access_token,
            token_type: responseData?.token_type,
          })
        );
        store.dispatch(
          UserActions.storeTokenToRedux(responseData.access_token)
        );
        sessionStorage.setItem("token_advin", responseData.access_token);
        store.dispatch(UserActions.storeUserIdToRedux(responseData.userId));
        store.dispatch(UserActions.storeUserRoleToRedux(responseData.userRole));
        yield put(UserActions.getSelf.request());
      } else {
        _.set(error, "description", loginResponse?.data?.message);
        _.set(error, "code", loginResponse.status);
        yield call(toast.error, "Wrong Email or password. Try again");
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.login.failure(extractError(error)));
    }
  }
}

function* logout(action: Action) {
  const state: ReturnType<typeof userSelector> = yield select(userSelector);
  let token = state.userToken;
  if (UserActions.logout.request.match(action)) {
    try {
      yield put(UserActions.storeTokenToRedux(""));
      yield put(UserActions.storeUserIdToRedux(undefined));
      yield put(UserActions.storeUserRoleToRedux(undefined));
      sessionStorage.removeItem("token_advin");
      if (token) {
        const logoutResponse: ApiResponse<LogoutResponse> = yield call(
          api.logoutGet,
          token
        );
      }
      yield put(UserActions.logout.success());
    } catch (error) {
      yield put(UserActions.logout.failure(extractError(error)));
    }
  }
}

function* tokenRelevanceCheck(action: Action) {
  if (UserActions.tokenRelevanceCheck.request.match(action)) {
    try {
      let { error } = action.payload;

      if (error.code !== 401) {
        // 401 means 'not authorized'
        yield put(
          UserActions.tokenRelevanceCheck.success({
            tokenExpiried: false,
          })
        );
      } else {
        yield put(UserActions.logout.request());
        yield put(
          UserActions.tokenRelevanceCheck.success({
            tokenExpiried: true,
          })
        );
      }
    } catch (error) {
      yield put(UserActions.logout.request());
      yield put(UserActions.tokenRelevanceCheck.failure(extractError(error)));
    }
  }
}

function* getSelfRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (UserActions.getSelf.request.match(action)) {
    try {
      const error = {};
      const getSelfResponse: ApiResponse<GetSelfResponse> = yield call(
        api.getSelfGet,
        token
      );
      if (getSelfResponse.ok && getSelfResponse.data) {
        const responseData = getSelfResponse.data;
        yield put(UserActions.setMyProfile(responseData));
        yield put(UserActions.getSelf.success(responseData));
      } else {
        _.set(error, "description", getSelfResponse.data?.message);
        _.set(error, "code", getSelfResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(UserActions.getSelf.failure(extractError(error)));
    }
  }
}

export function* UserSaga() {
  yield* [
    takeLeading(
      UserActions.tokenRelevanceCheck.request.type,
      tokenRelevanceCheck
    ),
    takeLeading(UserActions.login.request.type, loginRequest),
    takeLeading(UserActions.logout.request.type, logout),
    takeLeading(UserActions.getSelf.request.type, getSelfRequest),
  ];
}
