import { buffers, eventChannel } from "redux-saga";
import { call, spawn, take } from "redux-saga/effects";
import { UserSaga } from "../logic/user/UserSagas";
import { AdminSaga } from "../logic/admin/AdminSagas";
import { RootStore, store } from "./store";
import { UserActions } from "src/logic/user/UserRedux";

const APP_LAUNCH = "advin";
var item_value = sessionStorage.getItem("token_advin");

if (item_value) {
  store.dispatch(UserActions.storeTokenToRedux(item_value));
}

function createNavigationChannel() {
  return eventChannel((emit) => {
    emit(APP_LAUNCH);
    return () => {};
  }, buffers.expanding(0));
}
const navigationChannel = createNavigationChannel();

function* applicationStart(store: RootStore) {
  try {
    while (true) {
      const navigationEvent = yield take(navigationChannel);
      console.log(navigationEvent);
    }
  } catch (e) {}
}
function* rootSaga(store: RootStore) {
  const sagas = [applicationStart, UserSaga, AdminSaga];

  yield* sagas.map((saga, index) =>
    spawn(function* sagaGuard() {
      while (true) {
        try {
          if (index === 0) {
            yield call(saga, store);
          } else {
            yield call(saga);
          }
          break;
        } catch (e) {
          console.log(e);
        }
      }
    })
  );
}

export { rootSaga };
