import ThemeConfig from "./theme";
import ScrollToTop from "./components/ScrollToTop";
import { Auth } from "./pages/Auth";

// ----------------------------------------------------------------------

export default function App() {
  return (
    <ThemeConfig>
      <ScrollToTop />
      <Auth />
    </ThemeConfig>
  );
}
