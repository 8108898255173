import { restActionCreatorHelper } from "src/helpers/restActionCreatorHelper";

const adminRestAction = restActionCreatorHelper(`admin`);

export const ListAllStoresRestActions = adminRestAction("list_all_stores");
export const ListAllStoresModalRestActions = adminRestAction(
  "list_all_stores_modal"
);
export const AddUserRestActions = adminRestAction("add_user");
export const DeleteUserRestActions = adminRestAction("delete_user");
export const SearchUsersRestActions = adminRestAction("search_users");
export const ListServicesRestActions = adminRestAction("list_services");
export const SearchUsersByStoreRestActions = adminRestAction(
  "search_users_by_store"
);
