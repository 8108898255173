import { Navigate, useRoutes } from "react-router-dom";
import DashboardLayout from "./layouts/dashboard";
import NotFound from "./pages/Page404";
import Users from "./pages/Users";

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "/home", element: <Navigate to="/home/users" replace /> },
        { path: "/", element: <Navigate to="/home/users" replace /> },
        { path: "/home/users", element: <Users /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
    { path: "/404", element: <NotFound /> },
  ]);
}
