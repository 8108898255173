import { useState, useEffect, useRef } from 'react';
import { getRandomInt } from './random';
import { LocalesStringStrict } from 'src/logic/user/UserRedux';

export type UseSearchValueHookReturnType = [
  string, 
  string, 
  (s: string) => void
];

// Хук для создания значения (инпута), к которому будет подвязан запрос поиска
// Возвращает переменную состояния, которая обновляется не чаще чем раз в updateInterval - 
// когда юзер перестал изменять значение инпута, а также пару переменная состояние - сеттер, для самого инпута
export const useSearchValue = (defaultValue: string, updateInterval: number = 8e2): UseSearchValueHookReturnType => {
  const [innerValue, setInnerValue] = useState(defaultValue);
  const [searchValue, setSearchValue] = useState(innerValue);

  const timerId = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    if (timerId?.current)
      clearTimeout(timerId.current);

    timerId.current = setTimeout(() => {
      setSearchValue(innerValue);
    }, updateInterval);
  }, [innerValue]);

  return [searchValue, innerValue, setInnerValue];
};

// Хук для получения рандомного значения каждый updateInterval ms времени
// Возвращает переменную состояния - актуальное значение
export const useRandomValue = (max: number, updateInterval: number = 1e3) => {
  const [value, setValue] = useState(getRandomInt(max));

  useEffect(() => {
    const interval = setInterval(() => {
      setValue(getRandomInt(max));
    }, updateInterval);

    return () => {
      clearInterval(interval);
    }
  }, []);

  return value;
};

// Хук для использования "зависимого" значения - комбинация useEffect и useState
// Возвращает актуальное значение переменной состояния - зависимого от deps значения по правилу processor
export const useDependentValue = (processor: () => any, deps: ReadonlyArray<any>) => {
  const [value, setValue] = useState(processor());

  useEffect(() => {
    const updateValue = () => {
      setValue(processor());
    };
  
    updateValue();
  }, deps);

  return value;
};

export type UseMultilanguageInputReturnType = [
  LocalesStringStrict,
  {
    en: (v: string) => void;
    es: (v: string) => void;
    ru: (v: string) => void;
  },
  (defaultValuesObj?: LocalesStringStrict) => void
];

const DefaultLocalesString = {
  en: "",
  es: "",
  ru: ""
};

// Хук для удобного использования инпута, предполагающего ввод сразу на нескольких языках (с переводами)
// Возвращает соответствующие языкам приложения значения инпутов и их сеттеры,
// а также функцию для установки значений (или очистки) всех полей сразу
export const useMultilanguageInput = (defaultValuesObj: LocalesStringStrict = DefaultLocalesString): UseMultilanguageInputReturnType => {
  const [enValue, setEnValue] = useState(defaultValuesObj.en);
  const [esValue, setEsValue] = useState(defaultValuesObj.es);
  const [ruValue, setRuValue] = useState(defaultValuesObj.ru);

  const clearValuesFunction = (defaultValuesObj: LocalesStringStrict = DefaultLocalesString) => {
    setEnValue(defaultValuesObj.en);
    setEsValue(defaultValuesObj.es);
    setRuValue(defaultValuesObj.ru);
  }

  return [
    {
      en: enValue,
      es: esValue,
      ru: ruValue
    },
    {
      en: setEnValue,
      es: setEsValue,
      ru: setRuValue
    },
    clearValuesFunction
  ];
};

export type UsePluralityStateReturnType = [
  Set<string>,
  (s: string) => boolean,
  () => void
];

// Хук для использования множества в качестве переменной состояния
// Возвращает множество, функцию добавления (удаления) элемента, и функцию очистки множества
export const usePluralityState = (defaultValue: Set<string> = new Set()): UsePluralityStateReturnType => {
  const [plurality, setPlurality] = useState(defaultValue);

  const clearPlurality = () => void(setPlurality(new Set()));

  const append = (s: string) => {
    let wasAdded = true;

    if (plurality.has(s)) {
      wasAdded = false;
      setPlurality(prev => {
        const next = new Set(prev);
        next.delete(s);
        return next;
      });
    } else 
      setPlurality(prev => new Set(prev).add(s));

    return wasAdded;
  }

  return [
    plurality,
    append,
    clearPlurality
  ];
}