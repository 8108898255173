import { CustomCommand } from "reactotron-core-client";
import { UserActions } from "./../logic/user/UserRedux";
import { store } from "./../store/store";

const Logout: CustomCommand = {
  command: "Logout",
  handler: () => {
    store.dispatch(UserActions.logout.request());
  },
};

const Login: CustomCommand = {
  command: "Login",
  description: "Вход в аккаунт",
  handler: () => {
    store.dispatch(
      UserActions.login.request({
        email: "",
        password: "",
      })
    );
  },
};

const GetSelf: CustomCommand = {
  command: "Get Self",
  description: "Получение своего профиля",
  handler: () => {
    store.dispatch(UserActions.getSelf.request());
  },
};

const commands = [Logout, Login, GetSelf];

export { commands };
