import {
  ActionReducerMapBuilder,
  createAction,
  createReducer,
} from "@reduxjs/toolkit";
import fp from "lodash/fp";
import {
  addRestReducers,
  createRestActions,
  getDefaultRestState,
} from "src/store/restHelper";
import { LocalesString } from "../user/UserRedux";
import { NodeRestStateType } from "./../../store/restHelper.d";
import {
  SearchUsersRestActions,
  AddUserRestActions,
  DeleteUserRestActions,
  SearchUsersByStoreRestActions,
  ListAllStoresRestActions,
  ListServicesRestActions,
  ListAllStoresModalRestActions,
} from "./AdminActions";

//0: Доступно всем пользователям, 1: Зарегистрированным пользователям, 2: Доступно только по подписке
export type AccessLevel = 0 | 1 | 2;

export enum SortType {
  LowToHigh = 0,
  HighToLow,
}

export type Name = {
  en?: string;
  es?: string;
  ru?: string;
};

export type Description = {
  en?: string;
  es?: string;
  ru?: string;
};

export type Course = {
  totalLessons: number;
  accessLevel: number;
  _id: string;
  name: string;
  description: string;
  image: string;
  available: boolean;
  done: boolean;
  completeLessons: number;
  percents: number;
  visible: boolean;
  tags: string[];
};

export type Category = {
  quntity: number;
  origPhoto: string;
  smallPhoto: string;
  _id: string;
  type: string;
  name: Name;
};

export type Tag = {};

export type Address = {};

export type UserData = {};

type SearchUsersResponse = {
  users: UserData[];
  message?: string;
};

type SearchUsersByStoreResponse = {
  users: UserData[];
  message?: string;
};

export type SurveyAnswer = {
  text: LocalesString;
  tag: string;
};

export type SurveyQuestion = {
  text: LocalesString;
  answers: SurveyAnswer[];
};

export const LOCALES_STRING_PLACEHOLDER = {
  en: "",
  es: "",
  ru: "",
};

export const SURVEY_ANSWER_PLACEHOLDER = {
  text: LOCALES_STRING_PLACEHOLDER,
  tag: "",
};

export const SURVEY_QUESTION_PLACEHOLDER: SurveyQuestion = {
  text: LOCALES_STRING_PLACEHOLDER,
  answers: [SURVEY_ANSWER_PLACEHOLDER],
};

export const SURVEY_CONSTRUCTOR_PLACEHOLDER = {
  title: LOCALES_STRING_PLACEHOLDER,
  questions: [SURVEY_QUESTION_PLACEHOLDER],
};

export type Survey = {
  _id: string;
  required: boolean;
  questions: SurveyQuestion[];
  complete: boolean;
  visible: boolean;
  title: LocalesString;
};

type CreateSurveyPayload = {
  questions: SurveyQuestion[];
  required: boolean;
  visible: boolean;
  title: LocalesString;
};

type SearchUsersPayload = {
  serviceId?: string;
  email?: string;
  name?: string;
  phone?: string;
  role?: string;
  index?: number;
};

type SearchUsersByStorePayload = {
  serviceId?: string;
  store?: string;
  email?: string;
  name?: string;
  phone?: string;
  role?: string;
  index?: number;
};

export type Product = {
  _id: string;
  name: LocalesString;
  fabricator: LocalesString;
  description: string;
  origPhoto: string;
  smallPhoto: string;
  gallery: string[];
  weight: number;
  price: number;
  recommendedAgeMounth: number;
};

export type Service = {
  appointDays: AppointDays;
  appointHours: AppointHours;
  visible: boolean;
  origPhoto: string;
  smallPhoto: string;
  waitApprove: boolean;
  rejected: boolean;
  approved: boolean;
  _id: string;
  name: Name;
  description: LocalesString;
  owner: {
    _id: string;
  };
  category: Category;
  price: number;
  appointInterval: number;
};

export type AppointDays = {
  mon: boolean;
  tue: boolean;
  wed: boolean;
  thu: boolean;
  fri: boolean;
  sat: boolean;
  sun: boolean;
};

export type AppointHours = {
  start: number;
  end: number;
};

export type CreateServicePayload = {
  appointInterval: number;
  price: number;
  category: string;
  name: Name;
  description: LocalesString;
  appointDays: AppointDays;
  appointHours: AppointHours;
};

export type ChangeCoursePositionPayload = {
  positions: { id: string; position: number }[];
};

export type ChangeLessonPositionPayload = {
  id: string;
  positions: { id: string; position: number }[];
};

export type ChangeStepPositionPayload = {
  id: string;
  lessonIndex: string;
  positions: { id: string; position: number }[];
};

export type DeleteTagPayload = {
  id: string;
};

type AddUserPayload = {
  data?: any;
  email: string;
  role?: string;
  password: string;
  serviceId?: string;
  store_id?: number;
  preferences?: any;
};

type AddUserResponse = {
  data?: any;
};

type DeleteUserPayload = {
  userId: any;
  serviceId?: string;
};

type DeleteUserResponse = {
  data: any;
};

type ListAllStoresResponse = {
  data: any;
};

type ListAllStoresPayload = {
  data?: any;
  serviceId?: any;
};

type ListAllStoresModalResponse = {
  data: any;
};

type ListAllStoresModalPayload = {
  data?: any;
  serviceId?: any;
};

type ListServicesResponse = {
  data: any;
};

type ListServicesPayload = {
  data?: any;
  serviceId?: any;
};

const listAllStoresRestActions = createRestActions<
  typeof ListAllStoresRestActions,
  ListAllStoresResponse,
  ListAllStoresPayload
>(ListAllStoresRestActions);

const listAllStoresModalRestActions = createRestActions<
  typeof ListAllStoresModalRestActions,
  ListAllStoresModalResponse,
  ListAllStoresModalPayload
>(ListAllStoresModalRestActions);

const listServicesRestActions = createRestActions<
  typeof ListServicesRestActions,
  ListServicesResponse,
  ListServicesPayload
>(ListServicesRestActions);

const addUserRestActions = createRestActions<
  typeof AddUserRestActions,
  AddUserResponse,
  AddUserPayload
>(AddUserRestActions);

const deleteUserRestActions = createRestActions<
  typeof DeleteUserRestActions,
  DeleteUserResponse,
  DeleteUserPayload
>(DeleteUserRestActions);

const searchUsersRestActions = createRestActions<
  typeof SearchUsersRestActions,
  SearchUsersResponse,
  SearchUsersPayload
>(SearchUsersRestActions);

const searchUsersByStoreRestActions = createRestActions<
  typeof SearchUsersByStoreRestActions,
  SearchUsersByStoreResponse,
  SearchUsersByStorePayload
>(SearchUsersByStoreRestActions);

const AdminActions = {
  listAllStores: listAllStoresRestActions,
  listAllStoresModal: listAllStoresModalRestActions,
  listServices: listServicesRestActions,
  deleteUser: deleteUserRestActions,
  addUser: addUserRestActions,
  searchUsers: searchUsersRestActions,
  searchUsersByStore: searchUsersByStoreRestActions,
};

type AdminStep = "";
type AdminRestNodes =
  | AdminStep
  | "listAllStores"
  | "listAllStoresModal"
  | "addUser"
  | "listServices"
  | "deleteUser"
  | "searchUsersByStore"
  | "searchUsers";
type AdminStore = {
  step: AdminStep;
  surveyConstructor: Partial<Survey>;
};
type AdminState = NodeRestStateType<AdminRestNodes, AdminStore>;

// @ts-ignore
const initialRestState: AdminState = {
  step: "",
  listServices: getDefaultRestState(),
  searchUsersByStore: getDefaultRestState(),
  listAllStores: getDefaultRestState(),
  listAllStoresModal: getDefaultRestState(),
  addUser: getDefaultRestState(),
  deleteUser: getDefaultRestState(),
  searchUsers: getDefaultRestState(),
};

type Builder = ActionReducerMapBuilder<AdminState>;

const adminReducer = createReducer(
  initialRestState,
  (builder) =>
    fp.flow([
      addRestReducers(listAllStoresRestActions, "listAllStores"),
      addRestReducers(listAllStoresModalRestActions, "listAllStoresModal"),
      addRestReducers(listServicesRestActions, "listServices"),
      addRestReducers(addUserRestActions, "addUser"),
      addRestReducers(deleteUserRestActions, "deleteUser"),
      addRestReducers(searchUsersRestActions, "searchUsers"),
      addRestReducers(searchUsersByStoreRestActions, "searchUsersByStore"),
    ])(builder) as Builder
);

export { adminReducer, AdminActions };
