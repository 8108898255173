export const divStyle = {
  display: "flex",
  justifyContent: "center",
  outline: "none",
  flexDirection: "column",
  padding: 40,
};
export const divStyle2 = {
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
  outline: "none",
};

export const divStyle3 = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  outline: "none",
  flexDirection: "column",
  padding: 40,
};

export const divStyle4 = {
  display: "flex",
  height: "60vh",
  width: "60vw",
};

export const inputStyle = {
  display: "flex",
  outline: "none",
};

export const inputStyle2 = {
  display: "flex",
  outline: "none",
  paddingBottom: 10,
  paddingTop: 10,
};

export const inputStyle3 = {
  display: "flex",
  outline: "none",
  height: 40,
};

export const inputStyle4 = {
  display: "flex",
  outline: "none",
  paddingBottom: 10,
  paddingTop: 10,
  width: "33%",
};

export const inputStyle5 = {
  display: "flex",
  outline: "none",
  paddingBottom: 10,
  paddingTop: 10,
  width: "24%",
};

export const inputStyle6 = {
  display: "flex",
  outline: "none",
  paddingBottom: 10,
  paddingTop: 10,
  width: "100%",
};

export const buttonStyle = {
  display: "flex",
  outline: "none",
  marginRight: 10,
};

export const inputRowStyle = {
  display: "flex",
  justifyContent: "space-between",
};
