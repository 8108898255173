import { create } from "apisauce";
import { getConfig } from "./../config/Config";
import {
  LoginRequest,
  LoginResponse,
  LogoutResponse,
  SearchUsersRequest,
  SearchUsersResponse,
  SearchUsersByStoreRequest,
} from "./../types/api";
import { GetSelfResponse } from "src/logic/user/UserRedux";
import { errorResponseTransform } from "./errorResponseTransform";

const config = getConfig();

const PAGINATION_CONFIG = {
  // Number of results to return per page
  limit: 15,
};

const sauce = create({
  baseURL: config.baseURL,
  headers: { Accept: "application/json" },
});

sauce.addResponseTransform(errorResponseTransform);

const createSauceAuthorized = (userToken: string) => {
  return create({
    baseURL: config.baseURL,
    headers: {
      Accept: "application/json",
      Authorization: "Bearer " + userToken,
    },
  });
};

const api = {
  sauce,
  loginPost: function (params: LoginRequest) {
    return sauce.post<LoginResponse>("/auth/login", params);
  },
  logoutGet: function (token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.get<LogoutResponse>("/auth/logout");
  },
  addUserPost: function (params: any, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<any>(`/external_data/users/add`, {
      password: params.password,
      email: params.email,
      role: params.role,
      preferences: {
        store_id: params.store_id,
        products_id: [],
      },
    });
  },
  listAllStoresPost: function (params: any, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<any>(`/external_data/stors/list`, params);
  },
  listAllStoresModalPost: function (params: any, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<any>(`/external_data/stors/list`, params);
  },
  deleteUserPost: function (params: any, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<any>(
      `/external_data/users/delete/${params.userId}`,
      { serviceId: params.serviceId }
    );
  },
  searchUsersPost: function (params: SearchUsersRequest, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<SearchUsersResponse>(
      `/external_data/users/list/`,
      params
    );
  },
  listServicesGet: function (params: SearchUsersRequest, token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.get<SearchUsersResponse>(`/services/list/0`, params);
  },
  searchUsersByStorePost: function (
    params: SearchUsersByStoreRequest,
    token: string
  ) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.post<SearchUsersResponse>(
      `/external_data/users/listbystore`,
      params
    );
  },
  getSelfGet: function (token: string) {
    const sauceAuthorized = createSauceAuthorized(token);
    return sauceAuthorized.get<GetSelfResponse>("/profile/");
  },
};

export { api, PAGINATION_CONFIG };
