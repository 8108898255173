import { Action } from "@reduxjs/toolkit";
import { ApiResponse } from "apisauce";
import _ from "lodash";
import { call, put, select } from "redux-saga/effects";
import { api, extractError, RequestError } from "src/api";
import { UserActions } from "src/logic/user/UserRedux";
import { BaseErrorType } from "src/store/restHelper.d";
import { takeLeading } from "src/store/sagaHelper";
import { SearchUsersResponse } from "src/types/api";
import { AdminActions } from "./AdminRedux";
import { userSelector } from "src/logic/user/UserSelectors";
import { adminSelector } from "./AdminSelectors";
import { toast } from "react-toastify";

function* addUserRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (AdminActions.addUser.request.match(action)) {
    try {
      const error = {};
      const { email, password, role, store_id } = action.payload;
      const addUserResponse: ApiResponse<any> = yield call(
        api.addUserPost,
        {
          email: email,
          password: password,
          role: role,
          store_id: store_id,
        },
        token
      );
      if (addUserResponse.ok || addUserResponse.data) {
        const responseData = addUserResponse.data;
        yield put(AdminActions.addUser.success(responseData));
        yield call(toast.success, "User updated");
      } else {
        _.set(error, "description", addUserResponse.data?.message);
        _.set(error, "code", addUserResponse.status);
        yield call(toast.error, "User not updated. Try again");
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.addUser.failure(extractError(error)));
    }
  }
}

function* listAllStoresRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (AdminActions.listAllStores.request.match(action)) {
    try {
      const error = {};
      const listAllStoresResponse: ApiResponse<any> = yield call(
        api.listAllStoresPost,
        action.payload,
        token
      );
      const STORE_DEFAULT = [
        {
          store_id: 1000000000000000,
          store_name: "Any store",
        },
      ];
      if (listAllStoresResponse.ok && listAllStoresResponse.data) {
        // const responseData = listAllStoresResponse.data;
        const responseData = listAllStoresResponse.data.results[0].stors;
        const allStores: any = STORE_DEFAULT.concat(responseData);
        yield put(
          AdminActions.listAllStores.success({
            data: { allStoresWithDefault: allStores, allStores: responseData },
          })
        );
      } else {
        _.set(error, "description", listAllStoresResponse.data?.message);
        _.set(error, "code", listAllStoresResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.listAllStores.failure(extractError(error)));
    }
  }
}

function* listAllStoresModalRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (AdminActions.listAllStoresModal.request.match(action)) {
    try {
      const error = {};
      const listAllStoresModalResponse: ApiResponse<any> = yield call(
        api.listAllStoresModalPost,
        action.payload,
        token
      );
      const STORE_DEFAULT = [
        {
          store_id: 1000000000000000,
          store_name: "Any store",
        },
      ];
      if (listAllStoresModalResponse.ok && listAllStoresModalResponse.data) {
        // const responseData = listAllStoresResponse.data;
        const responseData = listAllStoresModalResponse.data.results[0].stors;
        const allStores: any = STORE_DEFAULT.concat(responseData);
        yield put(
          AdminActions.listAllStoresModal.success({
            data: { allStoresWithDefault: allStores, allStores: responseData },
          })
        );
      } else {
        _.set(error, "description", listAllStoresModalResponse.data?.message);
        _.set(error, "code", listAllStoresModalResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.listAllStoresModal.failure(extractError(error)));
    }
  }
}

function* deleteUserRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  if (AdminActions.deleteUser.request.match(action)) {
    try {
      const error = {};
      const { serviceId, userId } = action.payload;
      const deleteUserResponse: ApiResponse<any> = yield call(
        api.deleteUserPost,
        { userId: userId, serviceId: serviceId },
        token
      );
      if (deleteUserResponse.ok && deleteUserResponse.data) {
        const responseData = deleteUserResponse.data;
        yield put(AdminActions.deleteUser.success(responseData));
        yield call(toast.success, "User deleted");
      } else {
        _.set(error, "description", deleteUserResponse.data?.message);
        _.set(error, "code", deleteUserResponse.status);
        yield call(toast.error, "User not deleted. Try again");
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.deleteUser.failure(extractError(error)));
    }
  }
}

function* searchUsersRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  const adminState = yield select(adminSelector);
  if (AdminActions.searchUsers.request.match(action)) {
    try {
      const error = {};

      // const usersInRedux = index === 0 ? [] : adminState.searchUsers.data.users;

      const searchUsersResponse: ApiResponse<SearchUsersResponse> = yield call(
        api.searchUsersPost,
        action.payload,
        token
      );
      if (searchUsersResponse.ok && searchUsersResponse.data) {
        const responseData = searchUsersResponse.data;
        // responseData.users = usersInRedux.concat(responseData.users);
        yield put(AdminActions.searchUsers.success(responseData));
      } else {
        _.set(error, "description", searchUsersResponse.data?.message);
        _.set(error, "code", searchUsersResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.searchUsers.failure(extractError(error)));
    }
  }
}

function* listServicesRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  const adminState = yield select(adminSelector);
  if (AdminActions.listServices.request.match(action)) {
    try {
      const error = {};

      // const usersInRedux = index === 0 ? [] : adminState.searchUsers.data.users;

      const listServicesResponse: ApiResponse<any> = yield call(
        api.listServicesGet,
        action.payload,
        token
      );
      if (listServicesResponse.ok && listServicesResponse.data) {
        const responseData = listServicesResponse.data;
        // responseData.users = usersInRedux.concat(responseData.users);
        yield put(AdminActions.listServices.success(responseData));
      } else {
        _.set(error, "description", listServicesResponse.data?.message);
        _.set(error, "code", listServicesResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.listServices.failure(extractError(error)));
    }
  }
}

function* searchUsersByStoreRequest(action: Action) {
  const state = yield select(userSelector);
  const token = state.userToken;
  const adminState = yield select(adminSelector);
  if (AdminActions.searchUsersByStore.request.match(action)) {
    try {
      const error = {};
      // const { index } = action.payload;

      // const usersInRedux = index === 0 ? [] : adminState.searchUsers.data.users;

      const searchUsersByStoreResponse: ApiResponse<any> = yield call(
        api.searchUsersByStorePost,
        action.payload,
        token
      );
      if (searchUsersByStoreResponse.ok && searchUsersByStoreResponse.data) {
        const responseData = searchUsersByStoreResponse.data;
        // responseData.users = usersInRedux.concat(responseData.users);
        yield put(AdminActions.searchUsersByStore.success(responseData));
      } else {
        _.set(error, "description", searchUsersByStoreResponse.data?.message);
        _.set(error, "code", searchUsersByStoreResponse.status);
        throw new RequestError(error as BaseErrorType);
      }
    } catch (error) {
      yield put(UserActions.tokenRelevanceCheck.request({ error }));
      yield put(AdminActions.searchUsersByStore.failure(extractError(error)));
    }
  }
}

export function* AdminSaga() {
  yield* [
    takeLeading(AdminActions.searchUsers.request.type, searchUsersRequest),
    takeLeading(AdminActions.listServices.request.type, listServicesRequest),
    takeLeading(
      AdminActions.searchUsersByStore.request.type,
      searchUsersByStoreRequest
    ),
    takeLeading(AdminActions.addUser.request.type, addUserRequest),
    takeLeading(AdminActions.listAllStores.request.type, listAllStoresRequest),
    takeLeading(
      AdminActions.listAllStoresModal.request.type,
      listAllStoresModalRequest
    ),
    takeLeading(AdminActions.deleteUser.request.type, deleteUserRequest),
  ];
}
